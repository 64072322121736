import * as React from "react";

import NavigationComponent from "../../components/navigation/navigation";
import Vigil from "@foxsenseinnovations/vigil-sdk-js";

const IndexPage = () => {
  React.useEffect(()=> {
    Vigil.initialize({
      apiKey: process.env.GATSBY_VIGIL_API_KEY ? process.env.GATSBY_VIGIL_API_KEY : "",
      clientVersion: process.env.GATSBY_VIGIL_CLIENT_VERSION ? process.env.GATSBY_VIGIL_CLIENT_VERSION : "" 
    });
  },[])
  
  return (
    <>
      <NavigationComponent isMedicareAdvantage={true} isPDP={false} />
    </>
  );
};

export default IndexPage;
